import "./style.css";
import act from "./img/act.png";
import logo from "./img/logo.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useEffect, useState } from "react";
import { getSales } from "../../Api/Api";
export default function Sales() {
  const Navigate = useNavigate();
  const [sales, setSales] = useState();
  useEffect(() => {
    getSales().then((data) => {
      setSales(data);
    });
  }, []);
  return (
    <div className="sales">
      <div className="nav" onClick={() => window.history.back()}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 7L9 13L15 19"
            stroke="#474753"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>Акции</p>
      </div>{" "}
      <div className="actionsList">
        {sales &&
          sales.length > 0 &&
          sales.map((em, index) => {
            return (
              <div
                className="actionItem"
                onClick={() => Navigate(routes.salePage + "?key=" + em.ID)}
              >
                <img src={em.Image} alt="" />
                <div className="actionDesc">
                  <img src={em.Logo} alt="" />
                  <p>{em.Title}</p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
