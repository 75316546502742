import "./style.css";
import icon from "./img/icon.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useEffect, useState } from "react";
import { getNews } from "../../Api/Api";
export default function News() {
  const Navigate = useNavigate();
  const [news, setNews] = useState();
  useEffect(() => {
    getNews().then((data) => {
      setNews(data);
    });
  }, []);
  return (
    <div className="news">
      <div className="nav" onClick={() => window.history.back()}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 7L9 13L15 19"
            stroke="#474753"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>Новости</p>
      </div>{" "}
      <div className="newsItems">
        {news &&
          news.length > 0 &&
          news.map((em, index) => {
            return (
              <div
                className="newItem"
                onClick={() => Navigate(routes.pageofnews + "?key=" + em.ID)}
              >
                <img src={icon} alt="" />
                <div>
                  <p>
                    {em.Title.slice(0, 25)}
                    {em.Title.length > 25 && "..."}
                  </p>
                  <span>
                    {em.Description.slice(0, 100)}
                    {em.Description.length > 100 && "..."}
                  </span>
                  <article>{em.DateTime}</article>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
