import { useContext, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import "./style.css";
import { auth } from "../../Api/Api";
import { Store } from "react-notifications-component";
import { UserContext } from "../../Context/user";
export default function Auth() {
  const [tel, setTel] = useState("");
  const [password, setPassword] = useState("");
  const Navigate = useNavigate();
  const [user, setUser] = useContext(UserContext);

  return (
    <div className="auth">
      <img className="logoReg" src="/img/logo.svg" alt="" />
      <h3 className="h4Main">Вход</h3>
      <div className="formAuth">
        <ReactInputMask
          className="inputComp"
          name="Tel"
          placeholder="Телефон"
          mask="+7(999)999 99 99"
          value={tel}
          onChange={(e) => setTel(e.target.value)}
        ></ReactInputMask>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Пароль"
          className="inputComp"
        />
        <div
          className="button"
          style={
            tel.length < 12 || password.length < 6
              ? { opacity: "0.5", pointerEvents: "none" }
              : {}
          }
          onClick={() => {
            auth(tel, password).then((response) => {
              if (response != "err") {
                localStorage.userData = JSON.stringify(response[0]);
                setUser(response);
                Navigate(routes.main);
              } else {
                Store.addNotification({
                  title: "Ошибка",
                  message: `Неверный логин или пароль`,
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__bounceIn"],
                  animationOut: ["animate__animated", "animate__backOutUp"],
                  dismiss: {
                    duration: 3000,
                    onScreen: true,
                  },
                });
              }
            });
          }}
        >
          Войти
        </div>
        <div className="back" onClick={() => Navigate(routes.registration)}>
          РЕГИСТРАЦИЯ
        </div>
      </div>
    </div>
  );
}
