import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
import "./style.css";

import { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import {
  addNew,
  addQuestion,
  addSale,
  changeQuestion,
  deleteNew,
  deleteQuestion,
  deleteSale,
  getQuestions,
} from "../../../Api/Api";
import { Store } from "react-notifications-component";

export default function AdminQuestions() {
  const [Questions, setQuestions] = useState();
  const [img, setImg] = useState([]);
  const [file, setFile] = useState(null);
  const [change, isChange] = useState(false);
  //Поля
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [time, setTime] = useState("");
  const [currentItem, setCurrentItem] = useState(null);
  const fileTypes = ["JPG", "PNG", "WEBP", "GIF"];

  function save() {
    addQuestion(title, desc).then((status) => {
      if (status == 200) {
        Store.addNotification({
          title: "Новости",
          message: `Новость успешно добавлена`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__bounceIn"],
          animationOut: ["animate__animated", "animate__backOutUp"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
        setFile(null);
        setDesc("");
        setName("");
        setTime();
        setTitle("");
        getQuestions().then((data) => {
          setQuestions(data);
        });
      }
    });
  }
  useEffect(() => {
    currentItem ? isChange(true) : isChange(false);
  }, [currentItem]);
  useEffect(() => {
    getQuestions().then((data) => {
      setQuestions(data);
    });
  }, []);
  const handleChange = (file) => {
    setFile(file);
  };
  return (
    <div className="cont AdminActions">
      <AdminMenu></AdminMenu>
      <div className="mainCont">
        <AdminHeader></AdminHeader>
        <div className="actionsBodyAdmin">
          <div className="actionsLeft">
            <h4>Часто задаваемые вопросы</h4>
            <p>Выберите элемент для редактирования</p>

            {Questions &&
              Questions.length > 0 &&
              Questions.map((em, index) => {
                return (
                  <div
                    className="quests"
                    onClick={() => {
                      setCurrentItem(em);
                    }}
                  >
                    <div>
                      <p
                        style={
                          currentItem && currentItem.ID == em.ID
                            ? { background: "#f61a41", color: "#fff" }
                            : {}
                        }
                      >
                        {em.Title}
                        <svg
                          width="37"
                          height="37"
                          viewBox="0 0 37 37"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 18.5C0 8.28273 8.28273 0 18.5 0C28.7173 0 37 8.28273 37 18.5C37 28.7173 28.7173 37 18.5 37C8.28273 37 0 28.7173 0 18.5Z"
                            fill="#F61A41"
                          />
                          <path
                            d="M12 16.5L18.5 22.5L25 16.5"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <span>{em.Description}</span>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="actionsRight">
            <h3>
              <span
                style={!change ? { color: "#F61A41" } : {}}
                onClick={() => {
                  setCurrentItem(null);
                }}
              >
                Добавление /
              </span>
              <span style={change ? { color: "#F61A41" } : {}}> Изменение</span>
            </h3>
            <p>Заполните поля и сохраните изменения</p>
            {change ? (
              <div className="actionsForm">
                <input
                  type="text"
                  value={currentItem ? currentItem.Title : ""}
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, Title: e.target.value })
                  }
                  placeholder="Заголовок"
                />
                <textarea
                  name=""
                  rows={12}
                  value={currentItem ? currentItem.Description : ""}
                  onChange={(e) =>
                    setCurrentItem({
                      ...currentItem,
                      Description: e.target.value,
                    })
                  }
                  placeholder="Описание"
                  id=""
                ></textarea>

                <div className="buttonsSett">
                  <div
                    className="saveButton"
                    onClick={() => {
                      changeQuestion(
                        currentItem.Title,
                        currentItem.Description,
                        currentItem.ID
                      ).then((status) => {
                        if (status == 200) {
                          Store.addNotification({
                            title: "Частые вопросы",
                            message: `Вопрос успешно изменен`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: [
                              "animate__animated",
                              "animate__bounceIn",
                            ],
                            animationOut: [
                              "animate__animated",
                              "animate__backOutUp",
                            ],
                            dismiss: {
                              duration: 3000,
                              onScreen: true,
                            },
                          });
                          getQuestions().then((data) => {
                            setQuestions(data);
                          });
                        }
                      });
                    }}
                  >
                    Сохранить
                  </div>
                  <div
                    className="closeButton"
                    onClick={() =>
                      deleteQuestion(currentItem.ID).then((status) => {
                        if (status == 200) {
                          Store.addNotification({
                            title: "Частые вопросы",
                            message: `Вопрос успешно удален`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: [
                              "animate__animated",
                              "animate__bounceIn",
                            ],
                            animationOut: [
                              "animate__animated",
                              "animate__backOutUp",
                            ],
                            dismiss: {
                              duration: 3000,
                              onScreen: true,
                            },
                          });
                        }
                        getQuestions().then((data) => {
                          setQuestions(data);
                        });
                      })
                    }
                  >
                    Удалить
                  </div>
                </div>
              </div>
            ) : (
              <div className="actionsForm">
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Вопрос"
                />
                <textarea
                  name=""
                  rows={12}
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  placeholder="Ответ"
                  id=""
                ></textarea>

                <div className="buttonsSett">
                  <div className="saveButton" onClick={() => save()}>
                    Добавить
                  </div>
                  <div className="closeButton">Отменить</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
