import "./style.css";
import timer from "./img/timer.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";

export default function FirstScreen() {
  const Navigate = useNavigate();
  return (
    <div className="firstScreen">
      <div className="screensTitle">
        <svg
          className="cashLogo"
          width="745"
          height="397"
          viewBox="0 0 745 397"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M450.423 169.441C444.979 169.441 438.8 169.294 431.884 169C425.116 168.853 419.378 168.706 414.67 168.559C414.523 165.174 414.376 159.878 414.228 152.668C414.228 145.311 414.155 136.704 414.008 126.846C414.008 116.988 413.934 106.542 413.787 95.5069C413.787 84.3248 413.714 73.1426 413.566 61.9605C413.566 50.6313 413.566 39.8905 413.566 29.7383C413.566 19.439 413.566 10.4639 413.566 2.81298C416.803 2.66585 420.702 2.51871 425.263 2.37157C429.825 2.22444 434.459 2.15088 439.168 2.15088C444.023 2.00374 448.143 1.93018 451.527 1.93018C451.527 8.84543 451.527 16.9378 451.527 26.2072C451.527 35.3294 451.453 44.9666 451.306 55.1188C456.456 51.7348 461.826 49.0864 467.417 47.1736C473.155 45.2609 478.746 44.3045 484.19 44.3045C501.552 44.3045 514.353 54.3832 522.592 74.5404C530.832 94.6977 534.951 126.037 534.951 168.559C531.273 168.853 526.565 169.074 520.827 169.221C515.088 169.368 507.143 169.441 496.991 169.441C496.991 97.7875 489.266 61.9605 473.817 61.9605C469.992 61.9605 466.019 63.8733 461.9 67.6987C457.927 71.5242 454.396 76.4531 451.306 82.4856C451.159 92.6378 451.012 102.496 450.865 112.059C450.865 121.476 450.791 130.157 450.644 138.102C450.644 146.047 450.57 152.815 450.423 158.406C450.423 163.85 450.423 167.529 450.423 169.441Z"
            fill="#474753"
          />
          <path
            d="M346.397 170.103C340.365 170.103 333.523 169.662 325.872 168.779C318.221 167.749 310.644 166.499 303.14 165.027C295.637 163.409 289.016 161.717 283.277 159.951L292.988 133.246C298.579 136.483 304.759 139.573 311.527 142.516C318.442 145.311 325.21 147.592 331.831 149.358C338.452 151.123 344.117 152.006 348.825 152.006C355.152 152.006 359.787 150.608 362.729 147.813C365.672 144.87 367.143 141.78 367.143 138.543C367.143 134.718 365.525 131.554 362.288 129.053C359.051 126.405 354.784 124.124 349.487 122.211C344.338 120.152 338.747 118.239 332.714 116.473C327.27 114.855 321.826 113.089 316.382 111.176C310.938 109.264 305.936 107.057 301.375 104.555C296.961 101.907 293.356 98.7437 290.56 95.0654C287.912 91.3871 286.588 86.9731 286.588 81.8234C286.588 75.2024 288.942 69.0964 293.65 63.5053C298.358 57.7671 305.053 53.1324 313.734 49.6012C322.415 46.07 332.714 44.3044 344.632 44.3044C354.343 44.3044 364.127 45.5551 373.985 48.0563C383.99 50.5576 393.112 54.0888 401.352 58.6499L388.993 79.6164C380.9 74.4667 373.029 70.2735 365.378 67.0365C357.874 63.6525 350.738 61.9604 343.97 61.9604C337.496 61.9604 332.567 63.3582 329.183 66.1537C325.799 68.9493 324.107 71.8184 324.107 74.761C324.107 77.5565 325.431 79.9107 328.079 81.8234C330.728 83.589 334.259 85.1339 338.673 86.4581C343.234 87.7823 348.09 89.1065 353.239 90.4307C358.977 91.7549 364.863 93.3734 370.895 95.2861C376.928 97.0517 382.519 99.3323 387.668 102.128C392.818 104.923 396.938 108.528 400.028 112.942C403.117 117.209 404.662 122.579 404.662 129.053C404.662 136.998 402.161 144.061 397.158 150.24C392.156 156.42 385.241 161.275 376.413 164.807C367.732 168.338 357.727 170.103 346.397 170.103Z"
            fill="#474753"
          />
          <path
            d="M193.308 170.103C180.802 170.103 170.576 167.087 162.631 161.055C154.833 154.875 150.934 146.856 150.934 136.998C150.934 128.023 154.171 119.71 160.644 112.059C167.265 104.408 176.388 97.9345 188.011 92.6377C199.635 87.1938 212.95 83.4419 227.958 81.382C222.661 68.4343 215.378 61.9604 206.109 61.9604C200.517 61.9604 193.308 64.0939 184.48 68.3607C175.652 72.6276 166.677 78.2186 157.555 85.1339L150.934 63.0639C172.856 50.5576 193.602 44.3044 213.171 44.3044C228.473 44.3044 240.758 48.4977 250.028 56.8843C259.297 65.1238 266.065 78.3658 270.332 96.6103C274.599 114.708 276.732 138.69 276.732 168.558C273.937 168.706 268.934 168.853 261.725 169C254.663 169.294 247.012 169.441 238.772 169.441C238.772 164.733 238.699 160.098 238.551 155.537C223.397 165.248 208.316 170.103 193.308 170.103ZM187.349 135.453C187.349 140.015 189.115 143.619 192.646 146.268C196.324 148.916 201.18 150.24 207.212 150.24C217.806 150.24 228.031 145.679 237.889 136.557C237.007 120.961 235.314 107.792 232.813 97.0517C227.075 98.2288 221.484 100.141 216.04 102.79C210.743 105.438 205.888 108.602 201.474 112.28C197.207 115.811 193.749 119.637 191.101 123.756C188.6 127.729 187.349 131.628 187.349 135.453Z"
            fill="#474753"
          />
          <path
            d="M79.8777 170.103C64.2816 170.103 50.5246 166.719 38.6069 159.951C26.8362 153.183 17.5668 143.767 10.7987 131.702C4.17768 119.637 0.867188 105.659 0.867188 89.7687C0.867188 77.1152 3.00062 65.3446 7.26748 54.4567C11.5343 43.5688 17.4197 34.0788 24.9235 25.9864C32.5744 17.8941 41.5495 11.5674 51.8488 7.00625C62.1482 2.44512 73.2567 0.164551 85.1745 0.164551C97.6808 0.164551 109.378 2.66582 120.266 7.66836C131.301 12.5238 140.055 19.1447 146.529 27.5313C135.788 40.0377 125.783 48.7921 116.514 53.7946C105.185 31.5775 91.5748 20.4689 75.6844 20.4689C64.9437 20.4689 56.2628 24.9565 49.6418 33.9316C43.168 42.9067 39.9311 54.9717 39.9311 70.1264C39.9311 79.9843 41.2553 89.6951 43.9036 99.2588C46.6992 108.675 50.3775 117.209 54.9386 124.86C59.6469 132.511 64.8701 138.617 70.6083 143.178C76.4937 147.592 82.4526 149.799 88.485 149.799C92.899 149.799 97.4601 148.696 102.168 146.489C106.877 144.134 111.291 140.971 115.41 136.998C119.677 132.879 123.208 128.318 126.004 123.315L146.529 139.426C128.579 159.878 106.362 170.103 79.8777 170.103Z"
            fill="#474753"
          />
          <path
            d="M666.71 322.058C633.443 322.058 616.809 291.7 616.809 230.982C616.809 220.838 617.256 209.947 618.151 198.311C621.732 198.012 626.58 197.789 632.697 197.64C638.962 197.49 646.944 197.416 656.641 197.416C655.745 212.334 655.298 225.611 655.298 237.248C655.298 260.222 657.088 277.154 660.668 288.045C664.398 298.786 670.067 304.156 677.675 304.156C682.748 304.156 687.82 302.068 692.892 297.891C698.113 293.714 702.589 288.194 706.319 281.331C706.169 269.546 706.02 256.343 705.871 241.723C705.871 226.954 705.871 211.812 705.871 196.297C711.987 196.297 718.701 196.371 726.011 196.521C733.47 196.67 739.586 196.894 744.36 197.192C744.36 209.276 744.286 221.509 744.136 233.891C744.136 246.273 744.062 258.133 743.913 269.471C743.913 280.809 743.838 290.954 743.689 299.905C743.54 308.706 743.391 315.569 743.241 320.492C737.423 320.79 731.381 321.014 725.116 321.163C718.85 321.312 712.808 321.387 706.99 321.387C706.841 317.807 706.691 313.182 706.542 307.513C693.713 317.21 680.435 322.058 666.71 322.058Z"
            fill="#F61A41"
          />
          <path
            d="M545.726 322.059C533.94 322.059 523.274 319.299 513.726 313.779C504.178 308.259 496.57 300.8 490.901 291.401C485.381 282.003 482.621 271.56 482.621 260.073C482.621 250.973 484.337 242.469 487.768 234.563C491.199 226.656 495.973 219.719 502.09 213.751C508.355 207.635 515.516 202.936 523.572 199.654C531.628 196.222 540.206 194.507 549.306 194.507C561.091 194.507 571.758 197.267 581.306 202.787C590.854 208.306 598.387 215.765 603.907 225.164C609.576 234.413 612.411 244.856 612.411 256.492C612.411 265.593 610.695 274.096 607.264 282.003C603.832 289.91 598.984 296.921 592.718 303.038C586.602 309.005 579.516 313.704 571.46 317.135C563.553 320.417 554.975 322.059 545.726 322.059ZM552.215 303.933C560.122 303.933 566.313 300.576 570.788 293.863C575.264 287 577.502 277.527 577.502 265.443C577.502 255.299 576.01 246.273 573.026 238.367C570.042 230.46 565.94 224.194 560.719 219.57C555.497 214.945 549.53 212.633 542.817 212.633C534.91 212.633 528.719 216.064 524.243 222.926C519.768 229.639 517.53 239.038 517.53 251.122C517.53 261.117 519.022 270.143 522.005 278.199C524.989 286.105 529.092 292.371 534.313 296.996C539.684 301.62 545.651 303.933 552.215 303.933Z"
            fill="#F61A41"
          />
          <path
            d="M370.77 350.478L368.532 329.443C376.439 328.995 383.525 327.951 389.791 326.31C396.205 324.818 402.024 322.431 407.245 319.149C400.979 306.618 394.639 293.266 388.224 279.094C381.809 264.921 375.693 250.973 369.875 237.248C364.206 223.374 359.283 210.693 355.105 199.206C360.476 198.311 367.189 197.416 375.245 196.521C383.301 195.626 391.208 194.88 398.965 194.283C402.695 205.77 407.618 219.57 413.734 235.681C419.851 251.793 426.49 268.725 433.65 286.478C438.573 276.483 443.273 264.026 447.748 249.108C452.224 234.189 457.072 216.213 462.294 195.178L489.37 198.311C482.06 225.164 474.377 248.138 466.322 267.234C458.415 286.18 449.762 301.695 440.364 313.779C431.114 325.863 420.746 334.814 409.259 340.632C397.772 346.45 384.942 349.732 370.77 350.478Z"
            fill="#F61A41"
          />
          <rect
            x="115.891"
            y="226.628"
            width="221.703"
            height="116.786"
            rx="19.7984"
            transform="rotate(0.256608 115.891 226.628)"
            fill="#F61A41"
          />
          <path
            d="M211.24 284.353L211.253 292.488L242.654 292.438L242.67 302.429L211.269 302.479L211.293 317.323L192.738 317.353L192.714 302.509L178.441 302.532L178.425 292.541L192.698 292.518L192.578 217.442L233.684 217.376C247.006 217.354 257.382 220.192 264.813 225.889C272.34 231.587 276.112 239.907 276.129 250.849C276.147 261.697 272.401 269.981 264.893 275.702C257.48 281.424 247.113 284.295 233.792 284.316L211.24 284.353ZM257.432 251.022C257.422 245.218 255.369 240.796 251.273 237.758C247.176 234.624 241.274 233.064 233.567 233.076L211.158 233.112L211.216 269.366L233.625 269.33C241.237 269.318 247.087 267.738 251.173 264.591C255.355 261.35 257.441 256.826 257.432 251.022Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M211.214 269.366L233.622 269.33C233.67 269.33 233.718 269.33 233.765 269.33C241.308 269.298 247.11 267.719 251.171 264.592C252.294 263.721 253.265 262.758 254.086 261.703C254.109 261.673 254.132 261.644 254.155 261.614C255.193 260.258 255.985 258.751 256.529 257.093C257.133 255.255 257.433 253.231 257.429 251.022C257.424 247.986 256.86 245.329 255.738 243.05C255.244 242.047 254.642 241.118 253.931 240.262C253.907 240.233 253.883 240.204 253.859 240.175C253.113 239.29 252.25 238.485 251.27 237.758C247.199 234.644 241.345 233.083 233.707 233.076C233.659 233.076 233.612 233.076 233.564 233.076L211.156 233.112L211.214 269.366ZM261.66 223.75C261.734 223.795 261.808 223.84 261.882 223.885C262.904 224.508 263.881 225.176 264.811 225.89C267.812 228.161 270.216 230.85 272.023 233.956C274.748 238.639 276.116 244.27 276.127 250.849C276.134 255.512 275.446 259.701 274.064 263.416C272.229 268.345 269.172 272.44 264.891 275.703C263.835 276.517 262.72 277.274 261.545 277.973C261.529 277.982 261.513 277.992 261.497 278.002C261.421 278.047 261.345 278.091 261.268 278.136C256.168 281.107 249.958 283.006 242.638 283.835C239.849 284.151 236.9 284.311 233.789 284.316L211.238 284.353L211.251 292.488L242.652 292.438L242.668 302.429L211.267 302.479L211.291 317.323L192.736 317.353L192.712 302.509L178.439 302.532L178.423 292.541L192.696 292.518L192.575 217.442L233.682 217.376C245.215 217.357 254.542 219.482 261.66 223.75ZM174.027 273.909L173.906 198.832L233.652 198.736C249.374 198.711 264.294 202.027 276.107 211.063C289.026 220.866 294.741 235.12 294.766 250.819C294.791 266.488 289.078 280.683 276.234 290.494C271.679 294.001 266.657 296.655 261.301 298.593L261.337 321.039L229.936 321.089L229.96 335.933L174.126 336.023L174.102 321.179L159.829 321.202L159.753 273.932L174.027 273.909Z"
            fill="#F61A41"
          />
        </svg>

        <p>
          Простые шаги <br /> для получения
        </p>
      </div>
      <div className="cash">
        кешбека
        <svg
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.1367 11.1717C28.0634 11.1664 27.9821 11.1664 27.8967 11.1664H24.5301C21.7727 11.1664 19.4141 13.3371 19.4141 16.1664C19.4141 18.9957 21.7741 21.1664 24.5301 21.1664H27.8967C27.9821 21.1664 28.0634 21.1664 28.1394 21.1611C28.7061 21.1269 29.241 20.8879 29.6446 20.4885C30.0481 20.0892 30.2927 19.5567 30.3327 18.9904C30.3381 18.9104 30.3381 18.8237 30.3381 18.7437V13.5891C30.3381 13.5091 30.3381 13.4224 30.3327 13.3424C30.2927 12.7761 30.0481 12.2437 29.6446 11.8443C29.241 11.4449 28.7061 11.2059 28.1394 11.1717M24.2354 17.4997C24.9447 17.4997 25.5194 16.9024 25.5194 16.1664C25.5194 15.4304 24.9447 14.8331 24.2354 14.8331C23.5247 14.8331 22.9501 15.4304 22.9501 16.1664C22.9501 16.9024 23.5247 17.4997 24.2354 17.4997Z"
            fill="white"
            fill-opacity="0.27"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M27.892 23.1666C27.9381 23.1647 27.9839 23.1738 28.0259 23.193C28.0678 23.2122 28.1047 23.241 28.1334 23.2771C28.1621 23.3132 28.1819 23.3556 28.1911 23.4008C28.2004 23.446 28.1988 23.4928 28.1866 23.5372C27.92 24.4866 27.4946 25.2972 26.8133 25.9772C25.8146 26.9772 24.5493 27.4186 22.9866 27.6292C21.4666 27.8332 19.5266 27.8332 17.076 27.8332H14.26C11.8093 27.8332 9.86797 27.8332 8.3493 27.6292C6.78664 27.4186 5.5213 26.9759 4.52264 25.9786C3.5253 24.9799 3.08264 23.7146 2.87197 22.1519C2.66797 20.6319 2.66797 18.6919 2.66797 16.2412V16.0919C2.66797 13.6412 2.66797 11.6999 2.87197 10.1799C3.08264 8.61724 3.5253 7.35191 4.52264 6.35324C5.5213 5.35591 6.78664 4.91324 8.3493 4.70258C9.8693 4.49991 11.8093 4.49991 14.26 4.49991H17.076C19.5266 4.49991 21.468 4.49991 22.9866 4.70391C24.5493 4.91458 25.8146 5.35724 26.8133 6.35457C27.4946 7.03724 27.92 7.84658 28.1866 8.79591C28.1988 8.8404 28.2004 8.88714 28.1911 8.93233C28.1819 8.97753 28.1621 9.0199 28.1334 9.05602C28.1047 9.09213 28.0678 9.12096 28.0259 9.14017C27.9839 9.15938 27.9381 9.16843 27.892 9.16658H24.5266C20.744 9.16658 17.4106 12.1532 17.4106 16.1666C17.4106 20.1799 20.744 23.1666 24.5266 23.1666H27.892ZM7.66797 9.83324C7.40275 9.83324 7.1484 9.9386 6.96086 10.1261C6.77333 10.3137 6.66797 10.568 6.66797 10.8332C6.66797 11.0985 6.77333 11.3528 6.96086 11.5403C7.1484 11.7279 7.40275 11.8332 7.66797 11.8332H13.0013C13.2665 11.8332 13.5209 11.7279 13.7084 11.5403C13.8959 11.3528 14.0013 11.0985 14.0013 10.8332C14.0013 10.568 13.8959 10.3137 13.7084 10.1261C13.5209 9.9386 13.2665 9.83324 13.0013 9.83324H7.66797Z"
            fill="white"
            fill-opacity="0.27"
          />
        </svg>
      </div>
      <img src={timer} className="clock" alt="" />
      <div className="button" onClick={() => Navigate(routes.instructions)}>
        Узнать как<div className="flare"></div>
      </div>
    </div>
  );
}
