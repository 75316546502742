import item from "./img/item.svg";
import "./style.css";
export default function Buy() {
  return (
    <div className="buy">
      <div className="nav" onClick={() => window.history.back()}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 7L9 13L15 19"
            stroke="#474753"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>Покупки</p>
      </div>
      <div className="buyItems">
        <div className="buyItem">
          <div className="balls">
            <p>Баллы</p>
            <span>2422</span>
          </div>
          <div className="rightBuyItem">
            <span>14.02.2023</span>
            <p>Ярче! Магазин у дома может быть </p>
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#F61A41" />
                <path
                  d="M7.02734 9.99982L9.18951 12.162L13.7841 7.56738"
                  stroke="white"
                  stroke-width="1.62162"
                />
              </svg>
              <p>Баллы зачислены</p>
              <span>242.20 ₽</span>
            </div>
          </div>
        </div>
        <div className="buyItem">
          <div className="balls">
            <p>Баллы</p>
            <span>2422</span>
          </div>
          <div className="rightBuyItem">
            <span>14.02.2023</span>
            <p>Ярче! Магазин у дома может быть </p>
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#F61A41" />
                <path
                  d="M7.02734 9.99982L9.18951 12.162L13.7841 7.56738"
                  stroke="white"
                  stroke-width="1.62162"
                />
              </svg>
              <p>Баллы зачислены</p>
              <span>242.20 ₽</span>
            </div>
          </div>
        </div>
        <div className="buyItem">
          <div className="balls">
            <p>Баллы</p>
            <span>2422</span>
          </div>
          <div className="rightBuyItem">
            <span>14.02.2023</span>
            <p>Ярче! Магазин у дома может быть </p>
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#F61A41" />
                <path
                  d="M7.02734 9.99982L9.18951 12.162L13.7841 7.56738"
                  stroke="white"
                  stroke-width="1.62162"
                />
              </svg>
              <p>Баллы зачислены</p>
              <span>242.20 ₽</span>
            </div>
          </div>
        </div>
        <div className="buyItem">
          <div className="balls">
            <p>Баллы</p>
            <span>2422</span>
          </div>
          <div className="rightBuyItem">
            <span>14.02.2023</span>
            <p>Ярче! Магазин у дома может быть </p>
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#F61A41" />
                <path
                  d="M7.02734 9.99982L9.18951 12.162L13.7841 7.56738"
                  stroke="white"
                  stroke-width="1.62162"
                />
              </svg>
              <p>Баллы зачислены</p>
              <span>242.20 ₽</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
