import { useEffect, useState } from "react";
import "./style.css";
import { Html5Qrcode } from "html5-qrcode";
import { addOperation, checkFn, getDataFromScan } from "../../Api/Api";
import { Store } from "react-notifications-component";
import { useContext } from "react";
import { UserContext } from "../../Context/user";
export default function QrScan({ open, setOpen }) {
  const [data, setData] = useState("");
  const [user, setUser] = useContext(UserContext);
  console.log(user);
  useEffect(() => {
    const config = { fps: 10, qrbox: { width: 200, height: 200 } };
    const html5QrCode = new Html5Qrcode("qrCodeContainer");

    const qrScanerStop = () => {
      if (html5QrCode && html5QrCode.isScanning) {
        html5QrCode
          .stop()
          .then(() => console.log("scannerstop"))
          .catch(() => console.log("error"));
      }
    };
    const qrCodeSuccess = (decodedText) => {
      //t=20240628T1808&s=999.00&fn=7380440700550382&i=1127&fp=2410962519&n=1
      let arrParams = decodedText.split("&");
      let fn;
      let fp;
      let sum;
      let date;
      arrParams.map((em) => {
        if (em.includes("t=")) {
          date = em.replace("t=", "");
        }
        if (em.includes("fn=")) {
          fn = em.replace("fn=", "");
        }
        if (em.includes("fp=")) {
          fp = em.replace("fp=", "");
        }
        if (em.includes("s=")) {
          sum = em.replace("s=", "");
        }
      });
      checkFn(fn).then((status) => {
        if (status == 200) {
          addOperation(sum, date, fn, fp, user[0].ID).then((response) => {
            if (response != "err") {
              Store.addNotification({
                title: "Успешно",
                message: `Qr отсканирован и находится на проверке`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__bounceIn"],
                animationOut: ["animate__animated", "animate__backOutUp"],
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });
            } else {
              Store.addNotification({
                title: "Ошибка",
                message: `Qr уже отсканирован`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__bounceIn"],
                animationOut: ["animate__animated", "animate__backOutUp"],
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });
            }
          });
        } else {
          Store.addNotification({
            title: "Ошибка",
            message: `Чек не принадлежит к магазинам партнеров`,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__bounceIn"],
            animationOut: ["animate__animated", "animate__backOutUp"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        }
      });

      setOpen(false);
    };
    if (open) {
      html5QrCode.start({ facingMode: "environment" }, config, qrCodeSuccess);
    }
    return () => {
      qrScanerStop();
    };
  }, [open]);
  return (
    <div className="popQr">
      <button onClick={() => setOpen(false)} className="closeQr">
        Закрыть
      </button>
      <div id="qrCodeContainer"></div>
      <p>{data}</p>
    </div>
  );
}
