import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
import "./style.css";
export default function AdminDillers() {
  return (
    <div className="cont">
      <AdminMenu />
      <div className="mainCont">
        <AdminHeader></AdminHeader>
        <p className="razrab">Раздел находится в разработке</p>
      </div>
    </div>
  );
}
