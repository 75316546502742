import { useNavigate } from "react-router-dom";
import "./style.css";
import { routes } from "../../routes";
import ReactInputMask from "react-input-mask";
import { useState } from "react";
import { registration } from "../../Api/Api";
export default function RegistrationComplete() {
  const Navigate = useNavigate();
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [password, setPassword] = useState("");
  return (
    <div className="regComplete">
      <img src="/img/logo.svg" alt="" />
      <h4 className="h4Main">Регистрация</h4>
      <p>Остался всего один шаг!</p>
      <input
        type="text"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        placeholder="Фамилия"
        className="inputComp"
      />
      <input
        type="text"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        placeholder="Имя"
        className="inputComp"
      />
      <input
        type="text"
        value={middleName}
        onChange={(e) => setMiddleName(e.target.value)}
        placeholder="Отчество"
        className="inputComp"
      />
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        className="inputComp"
      />
      <ReactInputMask
        className="inputComp"
        mask="+7(999)999 99 99"
        placeholder="Номер телефона"
        value={tel}
        onChange={(e) => setTel(e.target.value)}
      />{" "}
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Пароль"
        className="inputComp"
      />
      <div
        className="button"
        style={
          firstName.length < 2 ||
          lastName.length < 2 ||
          email.length < 5 ||
          tel.length < 8 ||
          password.length < 6
            ? { opacity: "0.6", pointerEvents: "none" }
            : {}
        }
        onClick={
          () => {
            Navigate(routes.main);
          }
          /*    registration(
            firstName + " " + middleName + " " + lastName,
            email,
            tel,
            password
          ).then((status) => {
            if (status == 200) {
             
            }

          }) */
        }
      >
        Зарегистрироваться
      </div>
      <div className="back" onClick={() => window.history.back()}>
        назад
      </div>
    </div>
  );
}
