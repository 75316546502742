import { useState } from "react";
import "./style.css";
import tels from "./tel.png";
import { authAdmin } from "../../../Api/Api";
import { routes } from "../../../routes";
import { useNavigate } from "react-router-dom";
export default function AuthAdmin() {
  const [reg, setReg] = useState(true);
  const Navigate = useNavigate();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  return (
    <div className="authAdmin">
      <div className="authAdmLeft">
        <p>Для входа в личный кабинет зарегистрируйтесь</p>
        <h3>
          <span
            style={
              !reg ? { color: "rgba(255,255,255,0.15)" } : { color: "#fff" }
            }
            onClick={() => setReg(true)}
          >
            Вход
          </span>{" "}
          <span
            style={
              reg ? { color: "rgba(255,255,255,0.15)" } : { color: "#fff" }
            }
            onClick={() => setReg(false)}
          >
            Регистрация
          </span>
        </h3>
        <h4>в личном кабинете</h4>

        {reg ? (
          <div className="regF">
            <div className="authAdmForm">
              <p>Авторизация</p>
              <input
                type="text"
                placeholder="Логин"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />

              <input
                type="password"
                placeholder="Пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span>Забыли пароль?</span>
              <div
                className="regs"
                onClick={() => {
                  authAdmin(login, password).then((data) => {
                    if (data != "error") {
                      localStorage.adminData = JSON.stringify(data);
                      Navigate(routes.adminPartners);
                    }
                  });
                }}
              >
                Войти
              </div>
            </div>
          </div>
        ) : (
          <div className="authAdmForm">
            <p>Регистрация</p>
            <input type="text" placeholder="Логин" />
            <input type="text" placeholder="Почта" />
            <input type="text" placeholder="Юридическое наименование" />
            <input type="text" placeholder="ИНН" />
            <input type="text" placeholder="Город" />
            <input type="password" placeholder="Пароль" />
            <div className="regs">Зарегистрироваться</div>
          </div>
        )}
      </div>
      <img src={tels} className="telA" alt="" />
    </div>
  );
}
