import { useEffect, useState } from "react";

import "./style.css";
import { routes } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { getUsers, getUserSell } from "../../../Api/Api";
import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
export default function AdminUserSellData() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const Navigate = useNavigate();
  let params = new URLSearchParams(document.location.search);

  useEffect(() => {
    getUserSell(params.get("key")).then((data) => {
      setUsers(data);
    });
  }, []);
  return (
    <div className="cont">
      <AdminMenu />
      <div className="mainCont">
        <AdminHeader></AdminHeader>
        <div className="usersCont">
          <div className="buttonsAndOther">
            <h4>Покупки пользователя</h4>
            <input type="text" value={search} placeholder="Поиск.." />

            <div className="downloadOth">
              Скачать отчет
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46967 12.0303C5.76256 12.3232 6.23744 12.3232 6.53033 12.0303L11.3033 7.25736C11.5962 6.96447 11.5962 6.48959 11.3033 6.1967C11.0104 5.90381 10.5355 5.90381 10.2426 6.1967L6 10.4393L1.75736 6.1967C1.46447 5.90381 0.989592 5.90381 0.696699 6.1967C0.403805 6.48959 0.403805 6.96447 0.696699 7.25736L5.46967 12.0303ZM5.25 0.5L5.25 11.5L6.75 11.5L6.75 0.5L5.25 0.5Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className="partnersTable">
            <div className="adminTableTitle rzd">
              <div className="ava">ID</div>
              <div className="pName">Фискальный накопитель</div>
              <div className="pRub">Фискальный признак документа</div>
              <div className="pUser">Сумма</div>
              <div className="pTel">Время сканирования</div>
              <div className="pWeb">Дата покупки</div>
            </div>
            {users &&
              users.length > 0 &&
              users.map((em, index) => {
                return (
                  <div className="admPcol rzd" key={"partner" + index}>
                    <div className="ava">{em.ID}</div>
                    <div className="pName">{em.Fn}</div>
                    <div className="pRub">{em.Fp}</div>
                    <div className="pUser">{em.Summ}</div>
                    <div className="pTel">{em.TimeOperation}</div>
                    <div className="pWeb">{em.DateOperation}</div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
