import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
import "./style.css";

import { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import {
  addNew,
  addSale,
  changeNew,
  deleteNew,
  deleteSale,
  getNews,
  uploadImage,
} from "../../../Api/Api";
import { Store } from "react-notifications-component";
import icon from "./img/icon.svg";
export default function AdminNews() {
  const [news, setNews] = useState();
  const [img, setImg] = useState([]);
  const [file, setFile] = useState(null);
  const [change, isChange] = useState(false);
  //Поля
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [time, setTime] = useState("");
  const [currentItem, setCurrentItem] = useState(null);
  const fileTypes = ["JPG", "PNG", "WEBP", "GIF"];

  function save() {
    const data = new FormData();

    data.append("file", file);
    fetch("https://domcash.ru/avatar.php", {
      method: "post",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        addNew(time, title, desc, data).then((status) => {
          if (status == 200) {
            Store.addNotification({
              title: "Новости",
              message: `Новость успешно добавлена`,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__bounceIn"],
              animationOut: ["animate__animated", "animate__backOutUp"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
            setFile(null);
            setDesc("");
            setName("");
            setTime();
            setTitle("");
            getNews().then((data) => {
              setNews(data);
            });
          }
        });
      });
  }
  useEffect(() => {
    currentItem ? isChange(true) : isChange(false);
  }, [currentItem]);
  useEffect(() => {
    getNews().then((data) => {
      setNews(data);
    });
  }, []);
  const handleChange = (file) => {
    setFile(file);
  };
  const handleChangeFile = (file) => {
    uploadImage(file).then((data) => {
      setCurrentItem({ ...currentItem, Image: "https://domcash.ru/" + data });
    });
  };
  return (
    <div className="cont AdminActions">
      <AdminMenu></AdminMenu>
      <div className="mainCont">
        <AdminHeader></AdminHeader>
        <div className="actionsBodyAdmin">
          <div className="actionsLeft">
            <h4>Новости</h4>
            <p>Выберите элемент для редактирования</p>

            {news &&
              news.length > 0 &&
              news.map((em, index) => {
                return (
                  <div
                    className="newItem"
                    onClick={() => {
                      setCurrentItem(em);
                    }}
                    style={
                      currentItem && currentItem.ID == em.ID
                        ? { background: "#fce4e4" }
                        : {}
                    }
                  >
                    <img src={icon} alt="" />
                    <div>
                      <p>{em.Title}</p>
                      <span>{em.Description.slice(0, 60)}...</span>
                      <article>{em.DateTime}</article>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="actionsRight">
            <h3>
              <span
                style={!change ? { color: "#F61A41" } : {}}
                onClick={() => {
                  setCurrentItem(null);
                }}
              >
                Добавление /
              </span>
              <span style={change ? { color: "#F61A41" } : {}}> Изменение</span>
            </h3>
            <p>Заполните поля и сохраните изменения</p>
            {change ? (
              <div className="actionsForm">
                <input
                  type="text"
                  value={currentItem ? currentItem.Title : ""}
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, Title: e.target.value })
                  }
                  placeholder="Заголовок"
                />
                <textarea
                  name=""
                  rows={12}
                  value={currentItem ? currentItem.Description : ""}
                  onChange={(e) =>
                    setCurrentItem({
                      ...currentItem,
                      Description: e.target.value,
                    })
                  }
                  placeholder="Описание"
                  id=""
                ></textarea>
                <input
                  type="date"
                  value={currentItem ? currentItem.DateTime : ""}
                  onChange={(e) =>
                    setCurrentItem({
                      ...currentItem,
                      DateTime: e.target.value,
                    })
                  }
                  placeholder="Дата публикации"
                />
                <FileUploader
                  handleChange={handleChangeFile}
                  name="file"
                  label="Загрузите или перетащите файл"
                  types={fileTypes}
                />
                <div className="buttonsSett">
                  <div
                    className="saveButton"
                    onClick={() => {
                      changeNew(
                        currentItem.DateTime,
                        currentItem.Title,
                        currentItem.Description,
                        currentItem.Image,
                        currentItem.ID
                      ).then((status) => {
                        if (status == 200) {
                          Store.addNotification({
                            title: "Новости",
                            message: `Новость успешно Изменена`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: [
                              "animate__animated",
                              "animate__bounceIn",
                            ],
                            animationOut: [
                              "animate__animated",
                              "animate__backOutUp",
                            ],
                            dismiss: {
                              duration: 3000,
                              onScreen: true,
                            },
                          });
                          getNews().then((data) => {
                            setNews(data);
                          });
                        }
                      });
                    }}
                  >
                    Сохранить
                  </div>
                  <div
                    className="closeButton"
                    onClick={() =>
                      deleteNew(currentItem.ID).then((status) => {
                        if (status == 200) {
                          Store.addNotification({
                            title: "Новости",
                            message: `Новость успешно удалена`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: [
                              "animate__animated",
                              "animate__bounceIn",
                            ],
                            animationOut: [
                              "animate__animated",
                              "animate__backOutUp",
                            ],
                            dismiss: {
                              duration: 3000,
                              onScreen: true,
                            },
                          });
                        }
                        getNews().then((data) => {
                          setNews(data);
                        });
                      })
                    }
                  >
                    Удалить
                  </div>
                </div>
              </div>
            ) : (
              <div className="actionsForm">
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Заголовок"
                />
                <textarea
                  name=""
                  rows={12}
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  placeholder="Описание"
                  id=""
                ></textarea>
                <input
                  type="date"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  placeholder="Дата"
                />
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  label="Загрузите или перетащите файл"
                />
                <div className="buttonsSett">
                  <div className="saveButton" onClick={() => save()}>
                    Добавить
                  </div>
                  <div className="closeButton">Отменить</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
